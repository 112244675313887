import { Row, Col } from "antd";
import React from "react";
import bannerBackground from '../../../../assets/images/svg/footer-banner.svg';
import IntouchIcon from "../../../base/icon/intouch";
import B2 from "../../../base/text/b2";

import styles from "./index.module.css";

const FooterContent: React.FC<any> = (props: any) => {
    return (

        <Row gutter={[{}, 50]} className={styles.footer}>
            <div className={styles.footerBanner}>
                <img src={bannerBackground} alt="banner" />
            </div>
            <Col style={{ width: '100%' }}>
                
                <Row gutter={[{}, 10]} style={{ paddingBottom: 50, marginTop: '4rem' }}>
                    <Col className={styles.icon} span={24} md={6} lg={3}>
                        <IntouchIcon />
                    </Col>
                    <Col className={styles.copyright} span={24} md={16} lg={19}>
                        <B2 >
                            Copyright since 2021 © PLMJ Advogados, Sociedade Multidisciplinar, SP, RL. Todos os direitos reservados.
                        </B2>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default FooterContent;